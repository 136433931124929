import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { ApiError } from 'app/config/axios';
import { ChildIndex, PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { TimeplateEntity } from '../interfaces';

const endpoint = '/timeplates';

export const timeplateStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, TimeplateEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const timeplateUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, TimeplateEntity>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const timeplateShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, TimeplateEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const timeplateIndex = queryData =>
  apiClient.get<any, PaginatedResponse<TimeplateEntity>>(endpoint, {
    params: queryData,
  });

export const useTimeplateIndex: <T = PaginatedResponse<TimeplateEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['indexCompanies', any], any>(
    ['indexCompanies', queryData],
    () => timeplateIndex(queryData),
    options,
  );

export const useTimeplateStore = (
  options?: MutationOptions<TimeplateEntity, FormData, any>,
) =>
  useMutation<TimeplateEntity, FormData, any>(data => timeplateStore(data), {
    ...options,
  });

export const useTimeplateUpdate = (
  id: number,
  options?: MutationOptions<TimeplateEntity, any, any>,
) =>
  useMutation<TimeplateEntity, any, any>(
    ['timeplateUpdate'],
    data => timeplateUpdate(id, data),
    {
      ...options,
    },
  );

export const useTimeplateShow = (
  id: number,
  options?: QueryObserverOptions<TimeplateEntity, any>,
  queryData?,
  key?,
) =>
  useQuery<TimeplateEntity, ['timeplateShow', number], any>(
    [key || 'timeplateShow', id],
    () => timeplateShow(id, queryData),
    options,
  );

export const useTimeplateFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<TimeplateEntity>, ApiError>,
): {
  data: PaginatedResponse<TimeplateEntity> | undefined;
  isLoading: boolean;
  refetch: any;
} => {
  const {
    data: idData,
    isFetching: idsIsloading,
    refetch,
  } = useTimeplateIndex(queryData, options);

  return { data: idData, isLoading: idsIsloading, refetch };
};

export const timeplateChangeStatus = (
  id,
  isDisabled,
): Promise<TimeplateEntity> => {
  return apiClient.patch(`${endpoint}/${id}`, { isDisabled });
};

export const useTimeplateChangeStatus = (
  options?: MutationOptions<TimeplateEntity, any, any>,
) =>
  useMutation<any, TimeplateEntity, any>(
    ['timeplateChangeStatus'],
    ({ id, isDisabled }) => timeplateChangeStatus(id, isDisabled),
    {
      ...options,
    },
  );

export const timeplateGhostClone = (id: number): Promise<TimeplateEntity> => {
  return apiClient.post(`${endpoint}/ghosts/${id}/clone`);
};

export const useTimeplateGhostClone = (
  options?: MutationOptions<TimeplateEntity, any, any>,
) =>
  useMutation<any, TimeplateEntity, any>(
    ['useTimeplateGhostClone'],
    id => timeplateGhostClone(id),
    {
      ...options,
    },
  );
export const timeplateClone = (data): Promise<TimeplateEntity> => {
  return apiClient.post(`${endpoint}/clone`, data);
};

export const useTimeplateClone = (
  options?: MutationOptions<TimeplateEntity, any, any>,
) =>
  useMutation<any, TimeplateEntity, any>(
    ['useTimeplateClone'],
    data => timeplateClone(data),
    {
      ...options,
    },
  );

export const timeplateDelete = (id: number): Promise<TimeplateEntity> => {
  return apiClient.delete(`${endpoint}/ghosts/${id}`);
};

export const useTimeplateDelete = (
  options?: MutationOptions<TimeplateEntity, any, any>,
) =>
  useMutation<any, TimeplateEntity, any>(
    ['useTimeplateDelete'],
    id => timeplateDelete(id),
    {
      ...options,
    },
  );

export const useTimeplateFilter = ({
  key = ['useSignatureFilter'],
  builder,
  config = {},
}: ChildIndex<TimeplateEntity>) => {
  const query = builder?.query() ?? '';
  return useQuery<PaginatedResponse<TimeplateEntity>, any>(
    [key, builder],
    () => apiClient.get(`${endpoint}?${query}`),
    {
      ...config,
      retry: false,
    },
  );
};
