import {
  MutationOptions,
  QueryObserverOptions,
  QueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { apiClient } from 'app/config/axios';

import { ApiError } from 'app/config/axios';
import { ChildIndex, PaginatedResponse } from 'app/interfaces';
import objectToFormData from 'utils/objectToFormData';

import { LocationEntity } from '../interfaces';

const endpoint = '/locations';

export const locationStore = data => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.post<FormData, LocationEntity>(endpoint, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const locationUpdate = (id, data) => {
  const formData = objectToFormData(data, { indices: true });
  return apiClient.patch<any, LocationEntity>(`${endpoint}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const locationShow = (id: number, queryData = {}) =>
  apiClient.get<FormData, LocationEntity>(`${endpoint}/${id}`, {
    params: queryData,
  });

export const locationIndex = queryData =>
  apiClient.get<any, PaginatedResponse<LocationEntity>>(endpoint, {
    params: queryData,
  });

export const useLocationIndex: <T = PaginatedResponse<LocationEntity>>(
  query: any,
  options?: QueryOptions<T, any>,
) => UseQueryResult<T, any> = (queryData, options) =>
  useQuery<any, ['locationIndex', any], any>(
    ['locationIndex', queryData],
    () => locationIndex(queryData),
    options,
  );

export const useLocationStore = (
  options?: MutationOptions<LocationEntity, FormData, any>,
) =>
  useMutation<LocationEntity, FormData, any>(data => locationStore(data), {
    ...options,
  });

export const useLocationUpdate = (
  id: number,
  options?: MutationOptions<LocationEntity, any, any>,
) =>
  useMutation<LocationEntity, any, any>(data => locationUpdate(id, data), {
    ...options,
  });

export const useLocationShow = (
  id: number,
  options?: QueryOptions<LocationEntity, any>,
  queryData?,
  key?: string,
) =>
  useQuery<LocationEntity, ['locationShow', number, string?], any>(
    ['locationShow', id, key],
    () => locationShow(id, queryData),
    options,
  );

export const useLocationFilterIndex = (
  queryData,
  options?: QueryObserverOptions<PaginatedResponse<LocationEntity>, ApiError>,
): {
  data: PaginatedResponse<LocationEntity> | undefined;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, isFetching, refetch } = useLocationIndex(queryData, options);

  return { data, isLoading: isFetching, refetch };
};

export const locationClone = (id: number): Promise<LocationEntity> => {
  return apiClient.post(`${endpoint}/ghosts/${id}/clone`);
};

export const useLocationClone = (
  options?: MutationOptions<LocationEntity, any, any>,
) =>
  useMutation<any, LocationEntity, any>(
    ['useLocationClone'],
    id => locationClone(id),
    {
      ...options,
    },
  );

export const locationDelete = id => {
  return apiClient.delete(`${endpoint}/ghosts/${id}`);
};

export const useLocationDelete = () =>
  useMutation(['useLocationDelete'], id => locationDelete(id));

export const useLocationFilter = ({
  key = ['useLocationFilter'],
  builder,
  config = {},
}: ChildIndex<LocationEntity>) => {
  const query = builder?.query() ?? '';
  return useQuery<PaginatedResponse<LocationEntity>, any>(
    [key, builder],
    () => apiClient.get(`${endpoint}?${query}`),
    {
      ...config,
      retry: false,
    },
  );
};
